import React from 'react'
import TwoActionsDialog from './generic/TwoActionsDialog'

export interface FavoritesShareButtonProps {}

import ShareIcon from '@mui/icons-material/Share'
import { Tooltip } from '@mui/material'

export default function FavoritesShareButton(props: FavoritesShareButtonProps) {
  const [openDialog, setOpenDialog] = React.useState(false)

  const handleClick = () => {
    setOpenDialog(true)
  }

  const handleDelete = () => {
    setOpenDialog(false)
  }

  const handleAbort = () => {
    setOpenDialog(false)
  }

  const dialog = (
    <span>
      Damit du deine Favoriten teilen kannst, müssen wir kurz die Liste mit
      unseren Server abgleichen. Dabei werden lediglich deine Favoriten
      gespeichert. Es werden keine weiteren personenbezogenen Daten erhoben. Du
      erhältst eine Web-Adresse zurück, die deine Kollektion beinhaltet.
    </span>
  )

  return (
    <>
      <Tooltip title="Diese Favoritenliste teilen">
        <ShareIcon className="iconButton" onClick={handleClick} />
      </Tooltip>
      <TwoActionsDialog
        dialog={dialog}
        negative="Nein"
        negativeAction={handleAbort}
        open={openDialog}
        positive="OK"
        positiveAction={handleDelete}
        setOpen={setOpenDialog}
        title={'Favoritenliste teilen?'}
      />
    </>
  )
}
